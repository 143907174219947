<template>
  <div>

    <el-dialog
      width="60%"
      v-model="showForm"
      v-if="currentTherapyProtocol"
      :before-close="closeDialog"
    >
      <h4>Therapie Protokoll: Studienzuweisung</h4>
      <h5 v-if="currentTherapyProtocol.meta && currentTherapyProtocol.meta.protocolown">{{currentTherapyProtocol.meta.protocolown.title}}</h5>
      <h5>{{currentTherapyProtocol.protocol.title}}</h5>
      <hr/>
      <h6>Weisen Sie dem o.g. Therapie-Protokoll eine oder mehrere Studien zu:</h6>
      <el-select v-model="surveysOfTherapyProtocol"
                 filterable
                 multiple
                 class="w-75">
        <el-option
          v-for="item in surveys"
          :key="item.id"
          :label="item.title_short"
          :value="item.id"
        />
      </el-select>
      <div class="text-right mt-5">
        <el-button type="info" @click="closeDialog">Abbrechen</el-button>
        <el-button type="warning" @click="saveSurveys">Speichern</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "SurveyTherapyProtocolFormModal",
  props: ['modelValue', 'therapyProtocol'],
  components: {},
  data() {
    return {
      currentTherapyProtocol: null,
      surveysOfTherapyProtocol: [],
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  computed: {
    ...mapGetters('surveys', ['surveys']),
  },
  watch: {
    'therapyProtocol': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    ...mapActions('surveys', ['getSurveys', 'getSurveysOfTherapyProtocol', 'saveSurveysOfTherapyProtocol']),
    async init() {
      if(this.surveys && this.surveys.length == 0) {
        this.getSurveys()
      }
      this.showForm = this.modelValue
      this.currentTherapyProtocol = this.therapyProtocol
      await this.getSurveysOfTP()
    },
    async getSurveysOfTP() {
      if(this.currentTherapyProtocol) {
        this.surveysOfTherapyProtocol = []
        const surveys = await this.getSurveysOfTherapyProtocol(this.currentTherapyProtocol.id)
        this.surveysOfTherapyProtocol = surveys.map((s) => s.id)
      }
    },
    async saveSurveys() {
      await this.saveSurveysOfTherapyProtocol({ therapy_protocol_id: this.currentTherapyProtocol.id, surveys: this.surveysOfTherapyProtocol })
      await this.getSurveysOfTP()
      this.onAfterSave()
    },
    closeDialog() {
      this.showForm = false
      this.currentTherapyProtocol = null
      this.$emit('update:modelValue', false)
    },
    onAfterSave() {
      this.$emit("after-save")
      this.closeDialog()
    }
  }
}
</script>
<style scoped>
</style>
